<template>
   <div class="h-full flex flex-col">
      <loader :loading="loading" :backdrop="true" />
      <List
      resource="getAllOccupancies"
      :fields="fields"
      ref="vuetable"
      :pageSize="18"
      track-by="unit"
      :search="true"
      :clickable="true"
      :isCommunityRequired="true"
      :initial-filter="filter"
      :filter-mapper="mapFilterToQuery"
      @rowData="getMoveIn"
      >
       <template v-slot:filters="props">
        <div class="form-row">
            <div class="form-col">
               <SelectInput
                  name="statusIds"
                  label="status"
                  :options="statuses"
                  multiple
               />
            </div>
          <div class="form-col">
            <DateRangeInput
              label="Move in date"
              name="start_date"
            />
          </div>
        </div>
        <div class="form-row justify-end">
          <button @click.stop="props.reset()" class="btn-transparent mr-8">Reset</button>
          <button class="btn-primary">Filter</button>
        </div>
      </template>
      <template  v-slot:current_status="data">
         <icon name="rotate" v-if="data.current_status == 'Active'"   class="h-6 w-6 inline mr-2 text-yellow-300 text-opacity-50  "></icon>
         <icon name="calendar" v-else class="h-6 w-6 inline mr-2 text-green-500"></icon>

         <p v-if="data.current_status == 'Active'" class="inline">active</p>
         <p v-else class="inline">future</p>
      </template>
      </List>
   </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import List from '@/components/move_in/List'
import DateRangeInput from '@/components/form/DateRangeInput';
import SelectInput from "@/components/form/SelectInput";
import DateTimeField from '@/components/auth/list/fields/DateTimeField';
import Icon from "@/components/ui/Icon";

export default {
   mixins: [NotifyMixin, AuthMixin, ModalNavigation,DateRangeFilterMixin],
   components: {
      Loader,
      List,
      Icon,
      DateRangeInput,
      SelectInput
   },
   data() {
      return {
         loading: false,
         filter: {
            moveInDate: {
               startDate: null,
               endDate: null,
            }
         },
         statuses: [{
            key: 0,
            value: 'active'
         },
         {
            key: 1,
            value: 'future'
         }],
         fields: [
            {
               name: DateTimeField,
               title: 'move in date',
               sortField: 'start_date',
               switch: {
                  source: 'start_date',
                  showTime: false
               }

            },
            {
               name: 'c_unit_name',
               title: 'unit',
               sortField: 'c_unit_name',
                formatter: (value) => {
                  if(value) return value
                  return 'N/A'
               },
            },
            {
               name: 'current_status',
               title: 'status',
               sortField: 'current_status'
            },
            {
               name: 'party',
               title: 'residents',
               sortField: 'party',
               formatter: (values) => {
                  let data = '';
                  if(values)
                  {
                    if(values.length>0)
                    {
                      values.slice(0,3).forEach((resident) => {
                        data += `<span class="border-gray-400 border-2 p-1 mr-3">
                        ${resident.c_given_name}
                        ${resident.c_family_name}</span>` ;
                      });

                      if(values.length > 3) data +=`+ ${values.length-3}`

                      return data
                    }
                    else{
                      return 'N/A'
                    }
                  }
               },
            },
         ],
      }
   },
   methods:{
      mapFilterToQuery(values) {
         const { from: lastMoveInFrom, to: lastMoveInTo } = this.getFilterDateRange(values.moveInDate);

         return {
            lastMoveInFrom,
            lastMoveInTo,
         }
      },
      getMoveIn(row) {
        this.$router.push({name: 'leases.lease-and-resident', params:{id: row.data.occupancy_id}});
      }

   },
}
</script>
